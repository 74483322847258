import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import { rhythm, headerFontFamily } from "../utils/typography"

import * as styles from "./layout.module.css"

const Layout = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
      navPages: allMdx(
        filter: { fields: { collection: { eq: "pages" }, navindex: { gte: 1 } } }
        sort: { fields: [fields___navindex], order: ASC }
      ) {
        nodes {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
      footerPages: allMdx(
        filter: { fields: { collection: { eq: "pages" }, navindex: { lte: -1 } } }
        sort: { fields: [fields___navindex], order: DESC }
      ) {
        nodes {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  `)

  const rootPath = `${__PATH_PREFIX__}/`
  let content

  if (location.pathname === rootPath) {
    content = children;
  } else {
    content = (
      <>
        <main>{children}</main>
      </>
    );
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        minWidth: rhythm(12),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <nav className={styles.nav} title="Main navigation">
        <Link
          to={`/`}
          title="The Scubaneer homepage"
          className={styles.logo}
          style={{fontFamily: headerFontFamily, fontWeight: 600}}
        >
          {data.site.siteMetadata.title}
        </Link>
        <ul className={styles.navList}>
          {
            data.navPages.nodes.map(node => (
              <li className={styles.navItem} key={node.id}>
                  <Link to={node.fields.slug} className={styles.navItemLink} activeClassName={styles.navItemLinkActive}>{node.frontmatter.title}</Link>
              </li>
            ))
          }
        </ul>
      </nav>
      {content}
      <footer className={styles.footer}>
        <div className={styles.copyright}>
          Copyright &copy; 2020 {new Date().getFullYear() > 2020 ? `- ${new Date().getFullYear()}`: ''} &mdash; The Scubaneer. All rights reserved.
        </div>
        <ul className={styles.navList}>
          {
            data.footerPages.nodes.map(node => (
              <li className={styles.navItem} key={node.id}>
                  <Link to={node.fields.slug} className={styles.navItemLink} activeClassName={styles.navItemLinkActive}>{node.frontmatter.title}</Link>
              </li>
            ))
          }
        </ul>
      </footer>
    </div>
  )
}

export default Layout
