import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = (_, options) => {
  return {
    "h1": {
      fontFamily: options.headerFontFamily.join(','),
    },
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    "a": {
      color: `#466f94`
    }
  }
}

Wordpress2016.headerFontFamily = ["Playfair Display", "serif"];
Wordpress2016.headerWeight = 700;
Wordpress2016.bodyFontFamily = ["Montserrat", "sans-serif"];
delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
export const headerFontFamily = typography.options.headerFontFamily;
export const bodyFontFamily = typography.options.bodyFontFamily;